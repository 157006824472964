<template>
    <el-card>
    <div class="selectSchool">
        <div class="">
                    <div class="tip">请选择学校 : </div>
                        <el-select  
                        class="tip" 
                        v-model="school_id"
                        filterable placeholder="请选择学校"
                        :filter-method="GetSchool">
                            <el-option
                                v-for="item in schoolList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                            </el-option>
                        </el-select>
                </div>
                <!-- 选择小游戏 -->
                <div class="tip">
                    <div class="tip">请选择小游戏 :  </div>
                        <el-select  
                        v-model="game_id"
                        placeholder="请选择小游戏">
                            <el-option
                                v-for="item in gameList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                            </el-option>
                        </el-select>
                </div>
                <!-- 选择小游戏类型 -->
                <div class="tip">
                    <div class="tip">请选择小游戏类型 : </div>
                        <el-select  
                        v-model="game_type_id"
                        placeholder="请选择小游戏类型"
                        @focus="GetGameType">
                            <el-option
                                v-for="item in gameTypeList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                            </el-option>
                        </el-select>
                </div>
                <!-- 导出数据按钮 -->
                <div class="export" @click="exportMiniGameData"> 
                    <el-button class="themeButton">导出数据</el-button>
                </div>
    </div>
</el-card>
</template>
<style lang="scss" >

     @import "@/assets/css/manager/other/exportgameData.scss";
</style>
<script>
    import exportgameData from "@/assets/js/manager/other/exportgameData.js";
    export default {
        ...exportgameData
    };
</script>